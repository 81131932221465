import { graphql, Link, useStaticQuery } from "gatsby"
import loadable from "@loadable/component"
import React, { useMemo } from "react"
import { groupBy } from "lodash"
import { ContentfulArtistsPageQuery } from "../../../types/gatsby-graphql"
import { FilterComponent } from "../../components/FilterComponent/FilterComponent"
import { NFTCard } from "../../components/NFTCard/NFTCard"
import { SharedLayout } from "../../components/SharedLayout/SharedLayout"
import * as styles from "./Artists.module.scss"
import { enricheLotWithAuction, getAuctionsIds } from "../../utils/auction"

const Artists = () => {
  const dataResult: ContentfulArtistsPageQuery = useStaticQuery(graphql`
    query ContentfulArtistsPage {
      allContentfulLot {
        # group(field: artist___contentful_id) {
        edges {
          node {
            contentful_id
            ended
            lotId
            externalSiteUrl
            sale {
              nftType
            }
            auction {
              ...lotAuctionFragment
            }
            artist {
              firstName
              contentful_id
              username
              lastName
              middleName
              profilePicture {
                title
                gatsbyImageData(width: 75, height: 75)
                file {
                  url
                }
              }
            }
          }
        }
        # }
      }
    }
  `)

  const auctions = useMemo(() => {
    const lots = dataResult?.allContentfulLot.edges.filter(
      item => item.node.auction !== null
    )

    return getAuctionsIds(lots)
  }, [dataResult])

  const LoadableGalleryMonitor = loadable(
    () => import("../../components/GalleryMonitor/GalleryMonitor")
  )

  return (
    <SharedLayout
      title="Artists"
      blueCenter={false}
      body={
        <>
          <div className={styles.artistsContainer}>
            <FilterComponent />
            <LoadableGalleryMonitor
              auctions={auctions.filter(String)}
              render={({ result }: any) => {
                const enrichedData = enricheLotWithAuction(
                  dataResult?.allContentfulLot.edges || [],
                  result?.auctions || []
                )

                const data = groupBy(
                  enrichedData,
                  x => x.lot?.artist?.contentful_id
                )

                return (
                  <>
                    {Object.entries(data).map(([key, value]) => {
                      const artist = {
                        fullname: [
                          value[0].lot?.artist?.firstName,
                          value[0].lot?.artist?.middleName,
                          value[0].lot?.artist?.lastName,
                        ]
                          .join(" ")
                          .trim(),
                        contentful_id: value[0].lot?.artist?.contentful_id,
                        profile: value[0].lot?.artist?.profilePicture,
                        username: value[0].lot?.artist?.username,
                      }
                      console.log(artist)
                      return (
                        <div className={styles.artistsFoundationList}>
                          {artist.contentful_id && (
                            <Link to={`/artist/${artist.contentful_id}`}>
                              <div className={styles.artistsTitle}>
                                <img
                                  src={
                                    artist.profile?.gatsbyImageData?.images
                                      ?.fallback?.src!
                                  }
                                  alt={artist.profile?.title!}
                                />
                                <div className={styles.artistNameContainer}>
                                  <h1>{artist.fullname}</h1>
                                  <h3>{`(@${artist.username})`}</h3>
                                </div>
                              </div>
                            </Link>
                          )}
                          <div className={styles.artistsNFTList}>
                            {value.map(mapData => {
                              if (!mapData.lot?.auction) return null
                              return (
                                <NFTCard
                                  title={mapData?.lot?.auction?.mint?.name!}
                                  imgUrl={
                                    mapData?.lot?.auction?.mint?.mediaAsset
                                      ?.media?.file?.url!
                                  }
                                  price={mapData?.auction?.reservePriceInETH}
                                  by={`${mapData?.lot?.artist?.firstName} ${mapData?.lot?.artist?.lastName}`}
                                  assetType={
                                    mapData?.lot?.auction?.mint?.mediaAsset
                                      ?.media?.mimeType
                                  }
                                  linkTo={`/artwork/${mapData?.lot?.contentful_id}`}
                                />
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </>
                )
              }}
            />

            {/* {data.allContentfulLot.group.map(group => {
              const artistFullName = [
                group.edges[0].node?.artist?.firstName,
                group.edges[0].node?.artist?.middleName,
                group.edges[0].node?.artist?.lastName,
              ]
                .join(" ")
                .trim()
              return (
                <div className={styles.artistsFoundationList}>
                  <Link
                    to={`/artist/${group.edges[0].node.artist?.contentful_id}`}
                  >
                    <div className={styles.artistsTitle}>
                      <img
                        src={
                          group.edges[0].node.artist?.profilePicture?.file?.url!
                        }
                        alt={group.edges[0].node.artist?.profilePicture?.title!}
                      />
                      <div className={styles.artistNameContainer}>
                        <h1>{artistFullName}</h1>
                        <h3>{`(@${group.edges[0].node.artist?.username})`}</h3>
                      </div>
                    </div>
                  </Link>
                  <div className={styles.artistsNFTList}>
                    {group.edges.map(lot => {
                      return (
                        <NFTCard
                          title={lot.node.auction?.mint?.name!}
                          imgUrl={
                            lot.node.auction?.mint?.mediaAsset?.media?.file
                              ?.url!
                          }
                          price={0.45}
                          by={`${lot.node.artist?.firstName} ${lot.node.artist?.lastName}`}
                          assetType={
                            lot?.node.auction?.mint?.mediaAsset?.media?.mimeType
                          }
                          linkTo={`/artwork/${lot.node.contentful_id}`}
                        />
                      )
                    })}
                  </div>
                </div>
              )
            })} */}
          </div>
        </>
      }
    />
  )
}

export default Artists
